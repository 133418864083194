#canvas {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: -1;
}
video {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: -1;
  opacity: 0.01;
}

.status-message {
  position: fixed;
  top: 50%;
  left: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.02em;
  text-shadow: 0px 0px 4px rgba(57, 57, 57, 0.95);
  color: var(--white);
}

.status-message p {
  margin-top: 0.5em;
  line-height: 1.25em;
  color: var(--white);
}

#canvas-container {
  height: 100%;
  width: 100%;
  max-width: 768px;
  margin: auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  user-select: none;
  pointer-events: none;
  touch-action: none;
}

main:not(.camera-page) ~ #canvas-container {
  display: none;
}
